import { DocumentExportType } from 'app/models/document-export-type';
import { LocaleType } from 'app/models/locale-type';
import { axiosInstance } from 'app/constants/axios-instance';

export const getDocumentExportTypes = async (localeType: LocaleType): Promise<DocumentExportType[]> =>
  axiosInstance
    .get<DocumentExportType[]>('/document-export', {
      params: { localeType },
    })
    .then((response) => response.data);
