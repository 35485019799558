import React from 'react';

import { Customer } from './models/customer';
import { UserConfig } from './models/user-config';
import { DocumentExportType } from 'app/models/document-export-type';

export interface AppContextValue {
  customerDetails: Customer | null;
  config: UserConfig | null;
  documentExportTypes: DocumentExportType[] | null;
  reloadCustomerDetails: (onResult: (customer: Customer | null) => void) => void;
  reloadConfig: (onResult: (config: UserConfig | null) => void) => void;
}

export const AppContext = React.createContext<AppContextValue>({
  customerDetails: null,
  config: null,
  documentExportTypes: null,
  reloadCustomerDetails: () => undefined,
  reloadConfig: () => undefined,
});
