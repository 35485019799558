import { useTranslation } from 'react-i18next';

import { LocaleType } from 'app/models/locale-type';

export const useLocale = (): LocaleType => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;

  switch (resolvedLanguage) {
    case 'lt':
      return LocaleType.LT;
    case 'en':
      return LocaleType.EN;
    case 'cs':
      return LocaleType.CS;
    case 'de':
      return LocaleType.DE;
    case 'es':
      return LocaleType.ES;
    case 'fr':
      return LocaleType.FR;
  }

  throw new Error(`Unhandled language ${resolvedLanguage}`);
};
